import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Wrapper from '../../components/Wrapper/Wrapper';
import LeftSection from '../../components/LeftSection/LeftSection';
import RightSection from '../../components/RightSection/RightSection';
import MiddleSection from '../../components/MiddleSection/MiddleSection';
import AddTagModal from '../../components/Modals/AddTag/AddTag';
import EditFixedReplyModal from '../../components/Modals/FixedReply/EditFixedReply';
import * as actions from '../../store/actions/inbox_actions';
import io from "socket.io-client";
import soundfile from '../../assets/files/beep.mp3';

class Inbox extends Component {
	state = {
		add_tag_modal_show: false,
		add_efr_modal_show: false,
		replies: ''
	};

	visible = true;

	socket = io(process.env.REACT_APP_SERVER_URL, {
		transports: ['websocket']
	});

	vis = (function(){
		var stateKey, eventKey, keys = {
			hidden: "visibilitychange",
			webkitHidden: "webkitvisibilitychange",
			mozHidden: "mozvisibilitychange",
			msHidden: "msvisibilitychange"
		};
		for (stateKey in keys) {
			if (stateKey in document) {
				eventKey = keys[stateKey];
				break;
			}
		}
		return function(c) {
			if (c) document.addEventListener(eventKey, c);
			return !document[stateKey];
		}
	})();

	showHideModal = (modal, replies = null) => {
		if(modal === 'add_tag_modal') {
			this.setState((prevState) => ({ add_tag_modal_show: !prevState.add_tag_modal_show }));
		}
		if(modal === 'add_efr_modal') {
			this.setState((prevState) => ({ add_efr_modal_show: !prevState.add_efr_modal_show, replies: replies }));
		}
	}
	componentDidMount() {
		document.title = 'Inbox - listenyzen';
		document.body.classList.add('main-body');

		this.vis(function(){
			this.visible = this.vis() ? true : false;
		}.bind(this));

		// update project image & title
		this.props.projectInfo(this.props.match.params.project_id);

		// update project fixed replies
		this.props.fixedReplies(this.props.match.params.project_id);

		// update popular tags
		this.props.popularTags(this.props.match.params.project_id);
		
		//new message listener
		this.socket.on("new_message", data => {
			if(data.message) {
				if(data.message.project_id === this.props.match.params.project_id) {
					this.props.newMessage(data, this.props.currentUser);
					this.props.platformCount('messenger');
					if(this.props.currentUser.hasOwnProperty('psid')) {
						if(document.getElementById('conversation')) {
							document.getElementById('conversation').scrollTop = document.getElementById('conversation').scrollHeight;
						}
					}
					if(!this.visible) {
						if(data.message.replied_by && data.message.replied_by === 'Auto') {
							if(JSON.parse(localStorage.getItem('siteData')) && JSON.parse(localStorage.getItem('siteData')).hasOwnProperty('sound') && JSON.parse(localStorage.getItem('siteData')).sound === false) {
							} else {
								var audio = new Audio(soundfile);
								audio.play();
							}
						}
					}
					//this.props.projectInfo(this.props.match.params.project_id);
				}
			}
		});

		//new message handover listener
		this.socket.on("new_handover", data => {
			if(data.message) {
				if(data.message.project_id === this.props.match.params.project_id) {
					this.props.newHandover(data.message.psid, data.message.status);
					this.props.updateCurrentThread(data.message.status);
				}
			}
		});

		//new message handover updated
		this.socket.on("handover_updated", data => {
			if(data.message) {
				if(data.message.project_id === this.props.match.params.project_id) {
					this.props.newHandover(data.message.psid, data.message.status);
					this.props.updateCurrentThread(data.message.status);
				}
			}
		});
		
		//new comment listener
    	this.socket.on("new_comment", data => {
			if(data.comment) {
				if(data.comment.project_id === this.props.match.params.project_id) {
					this.props.newComment(data, this.props.currentUser);
					this.props.platformCount('facebook');
					if(!this.visible) {
						if(data.comment.sender_is === 'user' && !data.comment.answered_by && !data.comment.resolved_by) {
							if(JSON.parse(localStorage.getItem('siteData')) && JSON.parse(localStorage.getItem('siteData')).hasOwnProperty('sound') && JSON.parse(localStorage.getItem('siteData')).sound === false) {
							} else {
								var audio = new Audio(soundfile);
								audio.play();
							}
						}
					}
					//this.props.projectInfo(this.props.match.params.project_id);
				}
			}
		});

		//page comment delete listener
    	this.socket.on("page_comment_deleted", data => {
			if(data.comment_id) {
				if(data.project_id === this.props.match.params.project_id) {
					this.props.pageCommentDeleted(data, this.props.currentUser);
				}
			}
		});

		// update comment
		this.socket.on("update_comment", data => {
			if(data.comment) {
				if(data.comment.project_id === this.props.match.params.project_id) {
					this.props.updateComment(data, this.props.currentUser);
				}
			}
		});

		//new viber message listener
		this.socket.on("new_viber_message", data => {
			if(data.message) {
				if(data.message.project_id === this.props.match.params.project_id) {
					this.props.newvMessage(data, this.props.currentUser);
					this.props.platformCount('viber');
					if(this.props.currentUser.hasOwnProperty('id')) {
						if(document.getElementById('conversation_viber')) {
							document.getElementById('conversation_viber').scrollTop = document.getElementById('conversation_viber').scrollHeight;
						}
					}
					if(!this.visible) {
						if(JSON.parse(localStorage.getItem('siteData')) && JSON.parse(localStorage.getItem('siteData')).hasOwnProperty('sound') && JSON.parse(localStorage.getItem('siteData')).sound === false) {
						} else {
							var audio = new Audio(soundfile);
							audio.play();
						}
					}
					//this.props.projectInfo(this.props.match.params.project_id);
				}
			}
		});

		//new instagram listener
    	this.socket.on("new_instagram", data => {
			if(data.comment) {
				if(data.comment.project_id === this.props.match.params.project_id) {
					this.props.newiComment(data, this.props.currentUser);
					this.props.platformCount('instagram');
					//this.props.projectInfo(this.props.match.params.project_id);
					if(!this.visible) {
						if(JSON.parse(localStorage.getItem('siteData')) && JSON.parse(localStorage.getItem('siteData')).hasOwnProperty('sound') && JSON.parse(localStorage.getItem('siteData')).sound === false) {
						} else {
							var audio = new Audio(soundfile);
							audio.play();
						}
					}
				}
			}
		});
	}

	componentWillUnmount() {
		this.props.clearInbox();
		this.props.clearPlatformClicked();
		this.props.clearPlatformCount();
		this.props.clearPopularTags();
		this.props.clearProjectInfo();
		this.props.clearMessages();
		this.props.clearvMessages();
		this.props.clearComments();
		this.props.cleariComments();
		document.body.classList.remove('main-body');
		this.socket.close();
	}

	render() {
		return (
			<div className="Inbox">
				<Header />
				<Wrapper>
					<div className="social-activities-management">
						<div className="row social-activities-area">
							<LeftSection />
							<MiddleSection modal={this.showHideModal} />
							<RightSection modal={this.showHideModal} />
							<AddTagModal show={this.state.add_tag_modal_show} manage={this.showHideModal} project_id={this.props.match.params.project_id} />
							<EditFixedReplyModal show={this.state.add_efr_modal_show} replies={this.state.replies} manage={this.showHideModal} project_id={this.props.match.params.project_id} />
						</div>
					</div>
				</Wrapper>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		currentUser: state.inbox_state.currentUser
    };
}

const mapActionToProps = dispatch => {
    return {
			projectInfo: (project_id) => dispatch(actions.projectInfo(project_id)),
			clearInbox: () => dispatch(actions.clearInbox()),
			clearPlatformClicked: () => dispatch(actions.clearPlatformClicked()),
			clearPlatformCount: () => dispatch(actions.clearPlatformCount()),
			platformCount: (platform) => dispatch(actions.platformCount(platform)),
			clearProjectInfo: () => dispatch(actions.clearProjectInfo()),
			clearMessages: () => dispatch(actions.clearMessages()),
			clearvMessages: () => dispatch(actions.clearvMessages()),
			clearComments: () => dispatch(actions.clearComments()),
			cleariComments: () => dispatch(actions.cleariComments()),
			newMessage: (message, currentUser) => dispatch(actions.newMessage(message, currentUser)),
			newComment: (comment, currentUser) => dispatch(actions.newComment(comment, currentUser)),
			pageCommentDeleted: (comment, currentUser) => dispatch(actions.pageCommentDeleted(comment, currentUser)),
			newiComment: (comment, currentUser) => dispatch(actions.newiComment(comment, currentUser)),
			newvMessage: (message, currentUser) => dispatch(actions.newvMessage(message, currentUser)),
			updateComment: (comment, currentUser) => dispatch(actions.updateComment(comment, currentUser)),
			fixedReplies: (project_id) => dispatch(actions.fixedReplies(project_id)),
			popularTags: (project_id) => dispatch(actions.popularTags(project_id)),
			clearPopularTags: () => dispatch(actions.clearPopularTags()),
			newHandover: (psid, status) => dispatch(actions.newHandover(psid, status)),
			updateCurrentThread: (status) => dispatch(actions.updateCurrentThread(status))
    };
};

export default connect(mapStateToProps, mapActionToProps)(Inbox);