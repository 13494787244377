import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FacebookProvider, Login } from 'react-facebook';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import viberImage from '../../assets/img/social-icons/viber.svg';
import { connectInstagram, extendToken } from '../../services/ProjectService';
import { getUserData } from '../../services/UserService';
// import { clearDataReport } from '../../services/ReportService';
import ReactImageFallback from "react-image-fallback";
import spinner from '../../assets/img/login_spinner.gif';
import nophoto from '../../assets/img/nophoto.png';

const Project = (props) => {
	let facebook_btn_class = '';
	if(props.fb_connected === 'connected') facebook_btn_class = ['card-btn', 'facebook', 'disabled'].join(" ");
	else facebook_btn_class = ['card-btn', 'facebook'].join(" ");

	let instagram_btn_class = '';
	if(props.instagram_connected === 'connected') instagram_btn_class = ['card-btn', 'instagram', 'disabled'].join(" ");
	else instagram_btn_class = ['card-btn', 'instagram'].join(" ");

	let viber_btn_class = '';
	if(props.viber_connected === 'connected') viber_btn_class = ['card-btn', 'viber', 'disabled'].join(" ");
	else viber_btn_class = ['card-btn', 'viber'].join(" ");

	const handleError = (error) => {
		toast.error("😪 Couldn't connect to Facebook!", {
			autoClose: 5000,
			position: 'bottom-right',
			className: 'notification-message'
		});
	};

	const handleResponse = (data) => {
		extendToken(data.tokenDetail.accessToken, props.csrf)
		.then(response => {
			if(response.data.access_token) {
				var user_access_token = response.data.access_token;
				window.FB.api('/me/accounts','GET', {access_token: response.data.access_token, limit: 100}, function(response) {
					if (!response || response.error) {
						handleError('error');
					} else {
						props.modal('fb_page_list_modal', props.project_id, response.data, {}, user_access_token);
					}
				});
			} else {
				handleError('error');
			}
		})
		.catch(err => {});

		// window.FB.api('/oauth/access_token', {
		// 	grant_type: 'fb_exchange_token',
		// 	client_id: process.env.REACT_APP_FB_APP_ID,
		// 	client_secret: process.env.REACT_APP_FB_APP_SECRET,
		// 	fb_exchange_token: data.tokenDetail.accessToken
		// }, function(response) {
		// 	if (!response || response.error) {
		// 		handleError('error');
		// 	} else {
		// 		var user_access_token = response.access_token;
		// 		window.FB.api('/me/accounts','GET', {access_token: response.access_token}, function(response) {
		// 			if (!response || response.error) {
		// 				handleError('error');
		// 			} else {
		// 				props.modal('fb_page_list_modal', props.project_id, response.data, {}, user_access_token);
		// 			}
		// 		});
		// 	}
		// });
	};

	const connectInsta = () => {
		connectInstagram(props.project_id)
		.then(response => {
			if(response.data) {
				// instagram connected
				props.modal('insta_list_modal', props.project_id, [], {}, null, response.data.username);
				props.insta_subscribe(props.project_id);
			}
		})
		.catch(err => {});
	}

	// const clearData = () => {
	// 	clearDataReport(props.project_id)
	// 	.then(response => {
	// 		if(response.data) {
	// 			toast.success("😀 Old data successfully cleared!", {
	// 				autoClose: 5000,
	// 				position: 'bottom-right',
	// 				className: 'notification-message'
	// 			});
	// 		}
	// 	})
	// 	.catch(err => {});
	// }

	const numberWithCommas = (x) => {
		if(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		} else {
			return 0;
		}
	}

	return (
		<div className="col-sm-3 user-card-container">
			<div className="user-card">
				<div className="card">
					<div className="image">
						<Link to={"/dashboard/" + props.project_id}><ReactImageFallback src={props.photo} alt={props.name} title="Go to Dashboard" fallbackImage={nophoto} initialImage={spinner} /></Link>
					</div>

					<div className="card-text">
						<div className="card-text-inner">
							<h2>{props.name}</h2>
							<p>{props.email ? props.email : 'email address missing'}</p><br />
							<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Number of Queries Unresolved</Tooltip>}>
								<a href="#/">
									<span className="count">{numberWithCommas(props.unresolved)}</span>
								</a>
							</OverlayTrigger>

							<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Number of Messages Auto Replied</Tooltip>}>
								<a href="#/">
									<span className="acount">{numberWithCommas(props.auto_count)}</span>
								</a>
							</OverlayTrigger>

							{ getUserData().role === 'admin' ? <>&nbsp;&nbsp;<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Assign Project Manager</Tooltip>}>
								<a href="#/" onClick={props.modal.bind(this, 'pm_list_modal', props.project_id)} style={{fontSize: '16px'}}>
									<i className="fa fa-user-circle-o"></i>
								</a>
							</OverlayTrigger></> : null}

							{ getUserData().role === 'admin' || getUserData().role === 'company' ? <>&nbsp;&nbsp;<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Manage Tags</Tooltip>}>
								<Link to={
									{
										pathname: "/tag_manager/" + props.project_id
									}
								}>
									<i className="fa fa-tags"></i>
								</Link>
							</OverlayTrigger></> : null}

							{ getUserData().role === 'admin' && (props.intents && props.intents.length > 0) ? <>&nbsp;&nbsp;<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">AI based Reply Maker</Tooltip>}>
								<Link to={
									{
										pathname: "/replymaker/" + props.project_id,
										state: {
											intents: props.intents,
											entities: props.entities
										}
									}
								}>
									<i className="fa fa-superpowers" style={{fontSize: '16px'}}></i>
								</Link>
							</OverlayTrigger></> : null}
						</div>
					</div>


					<div className="card-buttons">
						<div className="card-buttons-inner">
							{ ((getUserData().role === 'admin' || getUserData().role === 'company') && props.fb_connected === 'disconnected') ? 
							<FacebookProvider appId={process.env.REACT_APP_FB_APP_ID} version={process.env.REACT_APP_FB_VERSION}>
								<Login scope="public_profile,email,manage_pages,pages_show_list,publish_pages,pages_messaging,read_page_mailboxes,instagram_basic,instagram_manage_comments"
								onCompleted={handleResponse}
								onError={handleError}>
								{({ loading, handleClick, error, data }) => ( 
									<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Connect Facebook Page</Tooltip>}>
										<a href="#/" onClick={handleClick} className={facebook_btn_class} data-toggle="modal">
											<span className="icon-font">
												<i className="fa fa-facebook"></i>
											</span>
											<span className="fb-assigned">
												<i className="fa fa-check"></i>
											</span>
										</a>
									</OverlayTrigger>
								)}</Login>
							</FacebookProvider> : props.fb_connected === 'connected' ? <a href="#/" className={facebook_btn_class} data-toggle="modal" title="Facebook Connected">
									<>
										<span className="icon-font">
											<i className="fa fa-facebook"></i>
										</span>
										<span className="fb-assigned">
											<i className="fa fa-check"></i>
										</span>
									</>
								</a> : null }
							
							{ ((getUserData().role === 'admin' || getUserData().role === 'company') && props.instagram_connected === 'disconnected') ? <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Connect Instagram Account</Tooltip>}>
								<a href="#/" onClick={connectInsta} className={instagram_btn_class} data-toggle="modal">
									<span className="icon-font">
										<i className="fa fa-instagram"></i>
									</span>
									<span className="fb-assigned">
										<i className="fa fa-check"></i>
									</span>
								</a>
							</OverlayTrigger> : props.instagram_connected === 'connected' ? <a href="#/" className={instagram_btn_class} title="Instagram Connected">
									<span className="icon-font">
									<i className="fa fa-instagram"></i>
									</span>
									<span className="fb-assigned">
										<i className="fa fa-check"></i>
									</span>
							</a> : null }

							{ ((getUserData().role === 'admin' || getUserData().role === 'company') && props.viber_connected === 'disconnected') ? <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Connect Viber Account</Tooltip>}>
								<a href="#/" onClick={props.modal.bind(this, 'viber_modal', props.project_id)} className={viber_btn_class} data-toggle="modal" title="Connect Viber">
									<span className="icon-font">
										<i><img src={viberImage} alt="Viber" /></i>
									</span>
									<span className="fb-assigned">
										<i className="fa fa-check"></i>
									</span>
								</a>
							</OverlayTrigger> : props.viber_connected === 'connected' ? <a href="#/" className={viber_btn_class} title="Viber Connected">
									<span className="icon-font">
										<i><img src={viberImage} alt="Viber" /></i>
									</span>
									<span className="fb-assigned">
										<i className="fa fa-check"></i>
									</span>
							</a> : null }

							{/* <a href="#/" className="card-btn whatsapp" title="Whatsapp">
								<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Whatsapp</Tooltip>}>
									<>
										<span className="icon-font">
										<i className="fa fa-whatsapp"></i>
										</span>
										<span className="fb-assigned">
											<i className="fa fa-check"></i>
										</span>
									</>
								</OverlayTrigger>
							</a> */}

							{ getUserData().role === 'admin' || getUserData().role === 'company' ? 
							<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Assign Query Manager</Tooltip>}>
								<a href="#/" onClick={props.modal.bind(this, 'qm_list_modal', props.project_id)} className="card-btn assign-qm" data-toggle="modal" title="Assign Query Manager">
									<span className="icon-font">
										<i className="fa fa-user"></i>
									</span>
								</a>
							</OverlayTrigger> : null }

							{ getUserData().role === 'query_manager' || getUserData().role === 'company' ?
							<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Add Fixed Reply</Tooltip>}>
								<a href="#/" onClick={props.modal.bind(this, 'fr_modal', props.project_id)} className="card-btn fixed-reply" data-toggle="modal">
									<span className="icon-font">
										<i className="fa fa-reply"></i>
									</span>
								</a>
							</OverlayTrigger> : null}

							{ getUserData().role === 'query_manager' || getUserData().role === 'admin' || getUserData().role === 'company' ?
							props.fb_connected === 'connected' || props.instagram_connected === 'connected' || props.viber_connected === 'connected' ? <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Inbox</Tooltip>}>
								{props.fb_connected === 'connected' ? <Link to={"/inbox/" + props.project_id + "/messenger"} className="card-btn inbox">
									<span className="icon-font">
										<i className="fa fa-comments-o"></i>
									</span>
								</Link> : props.instagram_connected === 'connected' ? <Link to={"/inbox/" + props.project_id + "/instagram"} className="card-btn inbox">
									<span className="icon-font">
										<i className="fa fa-comments-o"></i>
									</span>
								</Link> : <Link to={"/inbox/" + props.project_id + "/viber"} className="card-btn inbox">
									<span className="icon-font">
										<i className="fa fa-comments-o"></i>
									</span>
								</Link>}
							</OverlayTrigger> : null : null }

							{getUserData().role !== 'query_manager' ? <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View Report</Tooltip>}>
								<Link to={
									{
										pathname: "/dashboard/" + props.project_id
									}
								} className="card-btn report">
									<span className="icon-font">
										<i className="fa fa-file-text-o"></i>
									</span>
								</Link>
							</OverlayTrigger> : null}
						</div>
					</div>

					{/* { getUserData().role === 'admin' ? 
							<FacebookProvider appId={process.env.REACT_APP_FB_APP_ID} version={process.env.REACT_APP_FB_VERSION}>
								<Login scope="public_profile,email,manage_pages,publish_pages,pages_messaging,read_page_mailboxes,instagram_basic,instagram_manage_comments"
								onCompleted={handleResponse}
								onError={handleError}>
								{({ loading, handleClick, error, data }) => ( 
									<div id="fb-connect" className="facebook-connect-btn" onClick={handleClick}>
										<button type="text">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216" className="fb_svg" color="#FFFFFF">
												<path fill="#FFFFFF" d="
												M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
												11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
												11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
												15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
												11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"></path>
											</svg>
											<span>Continue with Facebook</span>
										</button>
									</div>
								)}</Login>
							</FacebookProvider> : null} */}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		csrf: state.site_state.csrf
  };
}

export default connect(mapStateToProps)(Project);