import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CardSection from '../../components/DashboardContent/CardSection/CardSection';
import TagCloudSection from '../../components/DashboardContent/TagCloudSection/TagCloudSection';
import GenderPieSection from '../../components/DashboardContent/GenderPieSection/GenderPieSection';
import RvrLineChart from '../../components/DashboardContent/RvrLineChart/RvrLineChart';
import TopicSection from '../../components/DashboardContent/TopicSection/TopicSection';
import IntentSection from '../../components/DashboardContent/IntentSection/IntentSection';
import PerformanceSection from '../../components/DashboardContent/PerformanceSection/PerformanceSection';
import FullReport from '../Modals/FullReport/FullReport';
import * as actions from '../../store/actions/report_actions';
import { getUserData } from '../../services/UserService';

class DashboardContent extends Component {
	state = {
		full_report_modal_show: false,
		type: null,
		data: null
	};

	showHideModal = (type = null, data = null) => {
		var x = document.getElementsByClassName("cwz btn");
		this.setState({
			type: type,
			data: data
		});
		if(!this.state.full_report_modal_show) {
			// z-index o
			for(let i=0; i< x.length; i++) {
				x[i].style.zIndex = '5';
			}
		} else {
			// z-index high
			for(let i=0; i< x.length; i++) {
				x[i].style.zIndex = '9999';
			}
		}
		this.setState((prevState) => ({ ...prevState, full_report_modal_show: !prevState.full_report_modal_show }));
	}

	componentDidMount() {
		var start_date = new Date(new Date().setMonth(new Date().getMonth() - 1));
		var end_date = new Date();
		this.props.projectInfo(this.props.match.params.project_id);
		this.props.setStartDate(start_date);
		this.props.setEndDate(end_date);
		if(getUserData().role === 'admin' || getUserData().role === 'company') {
			this.props.getReports(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date);
			this.props.getFollowup(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date);
			this.props.getUsers(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date, 'new');
			this.props.getUsers(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date, 'existing');
			this.props.getqrrReports(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date);
			this.props.gettagReports(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date);
			this.props.getgenderReports(this.props.match.params.project_id, start_date, end_date);
		}
		this.props.getperformanceReports(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date);
		// this.props.getentityReports(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date);
		// this.props.getintentReports(this.props.match.params.project_id, { value: '', label: 'Any' }, start_date, end_date);
	}

	componentWillUnmount() {
		this.props.clearReports();
	}

	render() {
		return (
			<div className="analytics-dashboard-content">
				{ (getUserData().role === 'admin' || getUserData().role === 'company') ? <div className="row">
					<div className="col-sm-5">
						<CardSection />
					</div>
					<div className="col-sm-7">
						<RvrLineChart />
					</div>
				</div> : null }
				{ (getUserData().userId !== '5e2965b7781f86217a260108' && getUserData().userId !== '5e29880c7a8bd0216a4afef0') ? <><hr className="listenyzen-bar" /><div className="row">
					<div className="col-sm-12">
						<PerformanceSection />
					</div>
				</div></> : null }
				{(getUserData().role === 'admin' || getUserData().role === 'company') ? <><hr className="listenyzen-bar" />
				<div className="row">
					<div className="col-sm-6">
						<TagCloudSection manage={this.showHideModal} />
					</div>
					<div className="col-sm-6">
						<GenderPieSection />
					</div>
				</div></> : null }

				{this.props.project_ai && (getUserData().role === 'admin' || getUserData().role === 'company')  ? 
				<><hr className="listenyzen-bar" />
				<div className="row">
					<div className="col-sm-6">
						<IntentSection manage={this.showHideModal} />
					</div>
					<div className="col-sm-6">
						<TopicSection manage={this.showHideModal} />
					</div>
				</div></> : null}
				{ (getUserData().role === 'admin' || getUserData().role === 'company') ? <FullReport show={this.state.full_report_modal_show} manage={this.showHideModal} type={this.state.type} data={this.state.data} /> : null }
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		project_ai: state.report_state.project_ai
	};
};

const mapActionToProps = dispatch => {
	return {
		projectInfo: (project_id) => dispatch(actions.projectInfo(project_id)),
		setStartDate: (start_date) => dispatch(actions.setStartDate(start_date)),
		setEndDate: (end_date) => dispatch(actions.setEndDate(end_date)),
		getReports: (project_id, platform, start_date, end_date) => dispatch(actions.getReports(project_id, platform, start_date, end_date)),
		getFollowup: (project_id, platform, start_date, end_date) => dispatch(actions.getFollowup(project_id, platform, start_date, end_date)),
		getUsers: (project_id, platform, start_date, end_date, user_type) => dispatch(actions.getUsers(project_id, platform, start_date, end_date, user_type)),
		getqrrReports: (project_id, platform, start_date, end_date) => dispatch(actions.getqrrReports(project_id, platform, start_date, end_date)),
		gettagReports: (project_id, platform, start_date, end_date) => dispatch(actions.gettagReports(project_id, platform, start_date, end_date)),
		//getentityReports: (project_id, platform, start_date, end_date) => dispatch(actions.getentityReports(project_id, platform, start_date, end_date)),
		//getintentReports: (project_id, platform, start_date, end_date) => dispatch(actions.getintentReports(project_id, platform, start_date, end_date)),
		getgenderReports: (project_id, start_date, end_date) => dispatch(actions.getgenderReports(project_id, start_date, end_date)),
		getperformanceReports: (project_id, platform, start_date, end_date) => dispatch(actions.getPerformance(project_id, platform, start_date, end_date)),
		clearReports: () => dispatch(actions.clearReports())
	};
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(DashboardContent));