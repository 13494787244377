import axios from '../api/public';
import * as cookieService from './CookieService';

export const getUserData = () => {
	var userData = cookieService.getCookie('__sign_h_p_l_s').split('.')[1];
	if(userData) {
		return JSON.parse(window.atob(userData));
	} else {
		return false;
	}
};

export const isLoggedIn = () => {
	if(getUserData() && getUserData().username && getUserData().userId) return true;
	else return false;
};

export const checkLogin = (facebook_user_id, access_token, email, password) => {
	return axios.post('/user/check', {
		facebook_user_id: facebook_user_id,
		access_token: access_token,
		email: email,
		password: password
	});
};

export const checkTrial = () => {
	return axios.get('/user/checkTrial');
};

export const signup = (userdata) => {
	return axios.post('/user/signup', userdata);
};

export const verify_email = (data) => {
	return axios.post('/user/verify', data);
};

export const forgetPassword = (data) => {
	return axios.post('/user/sendPasswordLink', data);
};

export const resetPassword = (data) => {
	return axios.post('/user/resetPassword', data);
};

export const logout = () => {
	return axios.get('/logout');
};